<template
>
  <div class="survey">
    <div class="row">
      <div class="col-lg-12" v-if="!isLoading">
        <div class="widget">
          <div class="widget-footer with-tabs bb-1">
            <ul class="nav nav-tabs nav-tabs-line" role="tablist">
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 1 }">
                <div class="nav-link" @click="setShowDetailSection(1)">{{ $t('labels.contact-information') }}</div>
              </li>
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 2 }">
                <div class="nav-link" @click="setShowDetailSection(2)">{{ $t('labels.geography') }}</div>
              </li>
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 3 }">
                <div class="nav-link" @click="setShowDetailSection(3)">{{ $t('labels.opening-hours') }}</div>
              </li>
              <li class="nav-item section" :class="{ 'current-section': showDetailSection === 7 }">
                <div class="nav-link" @click="setShowDetailSection(7)">{{ $t('labels.current-information') }}</div>
              </li>
            </ul>
          </div>
          <div class="widget-body"
               :class="{ 'no-padding': showDetailSection === 4 || showDetailSection === 5 || showDetailSection === 6 }">
            <div class="row" v-show="showDetailSection == 1">
              <div class="col br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.postal-address') }}</h5>

                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="addressee"
                    type="text"
                    :label="this.$t('labels.addressee')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="address_1"
                    type="text"
                    :label="this.$t('labels.address-one')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="address_2"
                    type="text"
                    :label="this.$t('labels.address-two')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="postal_code"
                    type="text"
                    :label="this.$t('labels.postal-code')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="postal_area"
                    type="text"
                    :label="this.$t('labels.postal-area')"
                ></text-field>
                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.visiting-address') }}</h5>

                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="visitor_address_1"
                    type="text"
                    :label="this.$t('labels.address-one')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="visitor_address_2"
                    type="text"
                    :label="this.$t('labels.address-two')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="visitor_postal_code"
                    type="text"
                    :label="this.$t('labels.postal-code')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="visitor_postal_area"
                    type="text"
                    :label="this.$t('labels.postal-area')"
                ></text-field>
              </div>
              <div class="col br-1">

                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.number') }}</h5>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="phone_1"
                    type="tel"
                    :label="this.$t('labels.phone-one')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="phone_2"
                    type="tel"
                    :label="this.$t('labels.phone-two')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="mobile_1"
                    type="tel"
                    :label="this.$t('labels.mobile-one')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="mobile_2"
                    type="email"
                    :label="this.$t('labels.mobile-two')"
                ></text-field>
                <text-field @updateLocalOffice="updateLocalOffice" :model="localOffice" field="fax" type="text"
                            label="Fax"></text-field>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.organisation') }}</h5>
                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="organization_number"
                    type="text"
                    :label="this.$t('labels.org-number-shorten')"
                ></text-field-office>
                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="bankgiro"
                    type="text"
                    :label="this.$t('labels.bankgiro')"
                ></text-field-office>
                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="plusgiro"
                    type="text"
                    :label="this.$t('labels.plusgiro')"
                ></text-field-office>
                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="swish"
                    type="text"
                    :label="this.$t('labels.swish')"
                ></text-field-office>
              </div>
              <div class="col-5">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.online2') }}</h5>

                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="email"
                    type="email"
                    :label="this.$t('labels.duty-email')"
                ></text-field-office>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="office_email"
                    type="email"
                    :label="this.$t('labels.office-email')"
                ></text-field>

                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="website"
                    type="text"
                    :label="this.$t('labels.homepage')"
                ></text-field-office>
                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="facebook"
                    type="text"
                    :label="this.$t('labels.facebook')"
                ></text-field-office>
                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="twitter"
                    type="text"
                    :label="this.$t('labels.twitter')"
                ></text-field-office>
                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="instagram"
                    type="text"
                    :label="this.$t('labels.instagram')"
                ></text-field-office>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.the-newspaper') }}</h5>

                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="newsletter_quantity"
                    type="text"
                    :label="this.$t('labels.number-of-ex')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="newsletter_subscription"
                    type="text"
                    :label="this.$t('labels.subscription')"
                ></text-field>
              </div>
            </div>

            <div class="row" v-show="showDetailSection == 2">
              <div class="col-4">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.victim-support-centre') }}</h5>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{ $t('labels.police-region') }}</label>
                  <div class="col-sm-8 pt-2">
                    {{ localOffice.police_region_list }}
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{ $t('labels.county') }}</label>
                  <div class="col-sm-8 pt-2">
                    {{ localOffice.county_list }}
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{ $t('labels.police-region') }}</label>
                  <div class="col-sm-8 pt-2">
                    {{ localOffice.office.label }}
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">{{ $t('labels.local-office') }}</label>
                  <div class="col-sm-8 pt-2">
                    <div v-for="local in localOffice.office.local_offices" :key="local.id">
                      {{ local.label }}
                    </div>
                  </div>
                </div>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.other') }}</h5>

                <checkbox-field-office
                    :model="localOffice.office"
                    field="has_room_conversation"
                    :label="this.$t('labels.has-room-conversation')"
                ></checkbox-field-office>
                <checkbox-field-office :model="localOffice.office" field="member_idea"
                                       :label="this.$t('labels.member-idea')"></checkbox-field-office>
                <checkbox-field-office
                    :model="localOffice.office"
                    field="has_employer_responsibility"
                    :label="this.$t('labels.has-employer-responsibility')"
                ></checkbox-field-office>

                <text-field-office
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice.office"
                    field="another_has_employer_responsibility"
                    type="text"
                    :label="this.$t('labels.another-has-employer-responsibility')"
                ></text-field-office>
              </div>
              <div class="col bl-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">
                  {{ $t('labels.municipalities') }} <span class="badge badge-primary ml-2 float-right">{{
                    localOffice.municipality_list_count
                  }}</span>
                </h5>
                <div class="row">
                  <div class="col-6 mb-2" v-for="municipality in localOffice.municipality" :key="municipality">
                    {{ municipality }}
                  </div>
                </div>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">
                  {{ $t('labels.districts') }} <span class="badge badge-primary ml-2 float-right">{{
                    localOffice.district_list_count
                  }}</span>
                </h5>
                <div class="text-muted" v-if="localOffice.district_list_count === 0">{{ $t('labels.no-neighborhoods-created-for') }}
                  {{ localOffice.label }}.
                </div>

                <div class="row" v-if="localOffice.district_list_count !== 0">
                  <div class="col-6 mb-2" v-for="district in localOffice.district" :key="district">
                    {{ district }}
                  </div>
                </div>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">
                  {{ $t('labels.postal-areas') }} <span class="badge badge-primary ml-2 float-right">{{
                    localOffice.postal_town.length
                  }}</span>
                </h5>

                <div class="row">
                  <div class="col-6 mb-2" v-for="postal_town in localOffice.postal_town" :key="postal_town">
                    {{ postal_town }}
                  </div>
                </div>
              </div>

              <div class="col bl-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">
                  {{ $t('labels.courts') }}<span class="badge badge-primary ml-2 float-right">{{ localOffice.court_list_count }}</span>
                </h5>
                <div class="text-muted" v-if="localOffice.court_list_count === 0">{{ $t('labels.no-courts-created-for') }}
                  {{ localOffice.label }}.
                </div>
                <div v-if="localOffice.court_list_count !== 0">
                  {{ localOffice.court_list }}
                </div>
                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">
                  {{ $t('labels.exposed-areas') }} <span
                    class="badge badge-primary ml-2 float-right">{{ localOffice.exposed_area_list_count }}</span>
                </h5>

                <div class="row" v-if="localOffice.exposed_area_list_count !== 0">
                  <div class="col-6 mb-2" v-for="exposed_area in localOffice.exposed_area" :key="exposed_area">
                    {{ exposed_area }}
                  </div>
                </div>

                <div class="text-muted" v-if="localOffice.exposed_area_list_count === 0">
                  {{ $t('labels.no-exposed-areas-created-for') }} {{ localOffice.label }}.
                </div>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">
                  {{ $t('labels.postal-code') }} <span class="badge badge-primary ml-2 float-right">{{
                    localOffice.postal_codes.length
                  }}</span>
                </h5>

                <div class="row">
                  <div class="col-2 mb-2" v-for="postal in localOffice.postal_codes" :key="postal">
                    {{ postal }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-show="showDetailSection == 3">
              <div class="col-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.forwarding-to-tc') }}</h5>
                <select-field
                    @updateMember="updateLocalOffice"
                    :clearable="false"
                    :multiple="true"
                    db_field="tc_relations"
                    :model="localOffice"
                    :options="localOfficeFieldOptions.tc_relations"
                    field="tc_relations"
                    :label="this.$t('labels.tc-connection')"
                ></select-field>

                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="email"
                    type="text"
                    :label="this.$t('labels.agency-email')"
                ></text-field>
                <text-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    field="contact_person"
                    type="text"
                    :label="this.$t('labels.contact-person-support')"
                ></text-field>
                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.urgent-tickets') }}</h5>
                <textarea-field @updateLocalOffice="updateLocalOffice" :model="localOffice" field="urgent_matters"
                                label=""></textarea-field>
              </div>
              <div class="col-4 br-1">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.availability') }}</h5>

                <textarea-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    :label="this.$t('labels.opening-hours')"
                    field="opening_hours"
                ></textarea-field>
                <textarea-field
                    @updateLocalOffice="updateLocalOffice"
                    :model="localOffice"
                    :label="this.$t('labels.mail-phone-frequency-check')"
                    field="mail_phone_frequency_check"
                ></textarea-field>
                <checkbox-field
                    :model="localOffice"
                    field="has_rotating_mobile"
                    :label="this.$t('labels.has-rotating-mobile')"
                ></checkbox-field>

                <h5 class="bb-1 pb-3 mb-3 mt-5" style="font-size: 1rem;">{{ $t('labels.other') }}</h5>
                <textarea-field @updateLocalOffice="updateLocalOffice" :model="localOffice" field="other_information"
                                label=""></textarea-field>
              </div>
              <div class="col-4">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.notes') }}</h5>

                <textarea-field @updateLocalOffice="updateLocalOffice" :model="localOffice" field="comments"
                                label=""></textarea-field>
              </div>
            </div>
            <div class="row" v-show="showDetailSection == 7">
              <div class="col-5 br-1 position-relative">
                <h5 class="bb-1 pb-3 mb-3" style="font-size: 1rem;">{{ $t('labels.submitted-and-ok') }}</h5>

                <div
                    v-if="informationFieldWait"
                    style="
										width: 100%;
										height: 100%;
										text-align: center;
										padding-top: 30%;
										font-size: 20px;
										background-color: #fff;
										opacity: 0.6;
										z-index: 100;
									"
                    class="position-absolute"
                >
                  <i class="fas fa-circle-notch fa-spin"></i>
                </div>
                <div class="row mb-4" v-for="informationField in sortedInformationFields" :key="informationField.id">
                  <div class="col-6 pt-2">
                    {{ informationField.label }}
                  </div>
                  <div class="col-1 text-center pt-2">
                    <input
                        type="checkbox"
                        @change="updateInformationField(localOffice, informationField, $event)"
                        class="mt-1 form-check"
                        v-model="informationField.checked"
                    />
                  </div>
                  <div class="col-5">
                    <input
                        type="date"
                        class="form-control fixed-padding"
                        @blur="updateInformationField(localOffice, informationField, $event)"
                        v-if="informationField.checked"
                        v-model="informationField.date"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BojAPI from '@/api/boj';
import TextField from '@/components/ticket/localoffice/TextField';
import TextFieldOffice from '@/components/ticket/office/TextField';
import SelectField from '@/components/ticket/localoffice/SelectField';
import CheckboxField from '@/components/ticket/localoffice/CheckboxField';
import CheckboxFieldOffice from '@/components/ticket/office/CheckboxField';
import TextareaField from '@/components/ticket/localoffice/TextareaField';
import MemberList from '@/components/lists/member/MemberList';

export default {
  name: 'survey-page',
  components: {
    TextField,
    TextFieldOffice,
    TextareaField,
    SelectField,
    CheckboxField,
    CheckboxFieldOffice,
    MemberList,
  },
  props: {
    id: Number,
  },

  computed: {
    localOfficeFieldOptions: function () {
      return this.$store.getters.localOfficeOptions;
    },

    sortedInformationFields() {
      const fields = this.localOffice.information_fields;

      return fields.sort(function (a, b) {
        return a.label < b.label;
      });
    },
    parentActions() {
      const actions = this.commentOptions;
      return actions.filter((item) => item.parent === null);
    },
    childActions() {
      if (this.comment.parentAction !== null) {
        let parentAction = this.comment.parentAction.id;
        const actions = this.commentOptions;
        return actions.filter((item) => item.parent === parentAction);
      } else {
        return [];
      }
    },

    childAction() {
      return this.ticketData.comments.filter((item) => item.id === this.comment.childAction.id);
    },
  },
  data() {
    return {
      informationFieldWait: false,
      comment: {
        comment: '',
        parentAction: null,
        childAction: null,
      },
      commentOptions: [],
      isLoading: true,
      localOffice: {
        label: '',
      },
      showDetailSection: 1,
    };
  },
  beforeRouteEnter(to, from, next) {
    BojAPI.getLocalOffice(to.params.id)
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    this.localOffice = null;
    BojAPI.getLocalOffice(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },
  mounted() {
    this.getActions();
  },
  methods: {
    /**
     * Update Information Field.
     *
     * @param localoffice
     * @param field
     * @param event
     */
    updateInformationField(localoffice, field, event) {
      this.informationFieldWait = true;
      if ((field.checked && field.foreign_id === null) || field.foreign_id !== null) {
        BojAPI.updateLocalOfficeInformationField(localoffice, field, event.target.value)
            .then((response) => {
              this.informationFieldWait = false;
              this.setData(response.data);
              this.$notify({
                group: 'foo',
                text: this.$t('labels.changes-saved'),
                type: 'success',
                duration: 3000,
              });
            })
            .catch((error) => {
              this.informationFieldWait = false;
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },

    /**
     * Save new comment
     */
    saveNewComment() {
      if (this.comment.parentAction == null || this.comment.childAction == null) {
        this.$notify({
          group: 'foo',
          text: this.$t('labels.missing-forum-event'),
          type: 'error',
          duration: 3000,
        });
      } else {
        BojAPI.saveLocalOfficeComment(this.localOffice.id, this.comment)
            .then(() => {
              BojAPI.getLocalOffice(this.localOffice.id).then((response) => {
                this.setData(response.data);
              });
              this.$notify({
                group: 'foo',
                text: this.$t('labels.note-saved'),
                type: 'success',
                duration: 3000,
              });

              this.comment = {
                comment: '',
                parentAction: null,
                childAction: null,
              };
            })
            .catch((error) => {
              this.$notify({
                group: 'foo',
                text: this.$t('labels.no-permission-adding-note-local-office'),
                type: 'warning',
                duration: 3000,
              });
            });
      }
    },

    /**
     * Get available actions
     */
    getActions() {
      BojAPI.getLocalOfficeActions().then((response) => {
        this.commentOptions = response.data.data;
      });
    },

    /**
     * Update Local Office
     * @param ticket
     */
    updateLocalOffice(ticket) {
      this.$emit('updateLocalOffice', ticket);
    },
    setShowDetailSection(section) {
      this.showDetailSection = section;
    },

    setData(payload) {
      this.localOffice = payload.data;
      this.isLoading = false;
    },
  },
};
</script>
